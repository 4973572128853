// src/store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    email: null,
  },

  mutations: {
    setEmail(state, email) {
      state.email = email;
    },
    clearEmail(state) {
      state.email = null;
    },
  },
  actions: {
    setEmail({ commit }, email) {
      commit('setEmail', email);
    },
    clearEmail({ commit }) {
      commit('clearEmail');
    },
  }
});
