<template>
  <footer class="bottom-app-bar">
    
    <!-- Home -->
    <p-button 
      label="Home"  
      icon="pi pi-home" 
      iconPos="top" 
      size="large" 
      :class="{'p-button-text': true, 'active': activePath === '/'}"
      @click="goTo('/')">
    </p-button>
    
    <!-- Categorias -->
    <p-button 
      label="Categorias" 
      icon="pi pi-bars" 
      iconPos="top" 
      size="large" 
      :class="{'p-button-text': true, 'active': activePath === '/category'}"
      @click="goTo('/category')">
    </p-button>

    <!-- Favoritos -->
    <p-button 
      label="Favoritos" 
      icon="pi pi-heart" 
      iconPos="top" 
      size="large" 
      :class="{'p-button-text': true, 'active': activePath === '/favorite'}"
      @click="goTo('/favorite')">
    </p-button>

    <!-- Carrinho -->
    <p-button 
      label="Carrinho" 
      icon="pi pi-shopping-cart" 
      iconPos="top" 
      size="large" 
      :class="{'p-button-text': true, 'active': activePath === '/search/cart'}"
      @click="goTo('/search/cart')">
    </p-button>
    
    <!-- Conta -->
    <p-button 
      label="Conta" 
      icon="pi pi-user" 
      iconPos="top" 
      size="large" 
      :class="{'p-button-text': true, 'active': activePath === '/about'}"
      @click="goTo('/about')">
    </p-button>
  </footer>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: 'BottomAppBar',
  
  components: {
    'p-button': Button
  },
  data() {
    return {
      activePath: this.$route.path
    };
  },
  watch: {
    '$route.path'(newPath) {
      this.activePath = newPath;
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path);
      this.activePath = path;
    }
  },
}
</script>

<style scoped>
.bottom-app-bar {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #ddd;
  padding: 0.5rem 0;
  z-index: 1000;
}

.p-button.active {
  color: #007bff; /* Cor do texto do botão ativo */
  background-color: #e6f7ff; /* Cor de fundo do botão ativo */
}
</style>
