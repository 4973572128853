import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const allProdutosFB = async (description='') => {
  const productsCollection = collection(db, 'Produtos');
  const productsQuery = query(productsCollection, orderBy('descricao', 'asc'))
  const productsSnapshot = await getDocs(productsQuery);

  const productsList = productsSnapshot.docs
  .map(doc => ({ id: doc.id, ...doc.data() }))
  .filter(item => item.descricao.trim().toLowerCase().includes(description.trim().toLowerCase()));

  return productsList;
};



export { allProdutosFB }