<template>

  <div class="search">
      <p-input-text class="search-input" type="text" v-model="descricao"></p-input-text>
      <p-button class="search-button" icon="pi pi-search" v-on:click="loadProducts()" />
  </div>

  <div class="product-list">
    <div v-on:click="onClickImage(product)" class="product-card" v-for="product in produtos" :key="product.id">

      <img :src="product.url_imagem" alt="Image" class="product-image">
        <div class="product-details">
          <p>{{ product.descricao }}</p>
          <p>{{ precoFormatado(product.preco) }}</p>
        </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { allProdutosFB } from '@/model/model_produto';

// PrimeVue
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { formatCurrency } from '@/utils/currency'


export default {

  name: 'HomeView',

  components: {
    'p-input-text': InputText,
    'p-button': Button,
  },
  
  setup() {
    const produtos = ref([]);
    const descricao = ref('')

    const loadProducts = async () => {
      produtos.value = await allProdutosFB(descricao.value)
    }

    onMounted(() => {
      loadProducts();
    });
    
    return { produtos, descricao, loadProducts };
  },
  
  methods:{

    onClickImage(produto) {
      this.$router.push({ path:'/product', query: { data: JSON.stringify(produto) }} );
    
    },

    precoFormatado(preco){
      return formatCurrency(preco);
    }
  },

}
</script>

<style scoped>
.template{
  scroll-margin-top: 90px;
}

.product-list {

  height: 600px; /* Altura fixa para a área rolável */
  overflow-y: auto; /* Adiciona barra de rolagem */
  flex: 1; /* Faz com que o corpo ocupe o espaço disponível */
  display: flex;
  flex-wrap: wrap;
  margin-top: 90px;
  margin-bottom: 100px;
  gap: 16px;
  justify-content: center;
}

.product-card {
  cursor: pointer;
  flex: 1 1 calc(50% - 16px); /* Dois cartões por linha com espaçamento */
  max-width: calc(50% - 16px);
  box-sizing: border-box;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-image {
  margin-top:10px;
  margin-left: 18px;
  width: 80%; /* Ajustado para 95% de largura */
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.product-details {
  padding: 16px;
}

.product-details h2,
.product-details h3 {
  margin: 8px 0;
}

.product-details p {
  margin: 8px 0;
}

.full-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-decoration: none;
  /* Estilo para aparência de link */
}

.search{
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid rgb(163, 159, 159);
  background-color:#ffffff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);

}

.search-input{
  width:400px;
  margin:10px;

}
.search-button{
  margin:10px;
}

</style>