<template>
  <div id="app">
    <router-view />
    <BottomAppBar />
  </div>
</template>

<script>
import BottomAppBar from './components/BottomAppBar.vue';

// Firebase
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../src/firebaseConfig';

import { useStore } from 'vuex';

export default {
  components: {
    BottomAppBar
  },

  created(){

    const store = useStore();

    onAuthStateChanged(auth, (user) => {
      if (user) {
      console.log('"App.vue" Usuário autenticado:', user.email);
      store.dispatch('setEmail', user.email);
      this.$router.push('/');

    } else {
      console.log('Usuário não autenticado');
      store.dispatch('clearEmail');
      this.$router.push('/login')
    }
  });

  }

}
</script>

<style>
/* Opcional: Estilo global para o corpo */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
</style>
