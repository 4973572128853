// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCcliHhj2SFT_VT4hRXB52Jo8ByBDy2gzQ",
    authDomain: "mobile-beb76.firebaseapp.com",
    projectId: "mobile-beb76",
    storageBucket: "mobile-beb76.appspot.com",
    messagingSenderId: "212392827300",
    appId: "1:212392827300:web:4146ef93301b1decb9c931"
  };

  const app = initializeApp(firebaseConfig);

// Exportar os serviços do Firebase
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
